import axios from "axios";
import { processing,success,failure } from "./axios";
import { API_URL } from "../../constants";
export const getTrialPackage = ({subscriberId,inventorySerial}) => {
    let url  = `${API_URL}/subscribers/owners/v1/subscriber/${subscriberId}/inventory/${inventorySerial}`
    return (dispatch) =>{
        dispatch(processing('TRAIL_PACKAGE_PROCESSING'),true);
        return axios.get(url).then(
            (response)=>{
                if(response.status === 200){
                    dispatch(success('TRAIL_PACKAGE_SUCCESS'),response)
                    return response
                }
            },(error)=>{
                dispatch(failure('TRAIL_PACKAGE_ERROR'),error)
                return error
            }
        )
    }
}

export const activeTrailPackage = (payload)=>{
    let url = `${API_URL}/subscribers/package/v1/subscriptions/subscribe`;
    return (dispatch) =>{
        dispatch(processing('ACTIVATE_PACKAGE_PROCESSING'),true);
        return axios.post(url,payload)
    }
}

export const claimRefund = (payload) =>{
    let url = `${API_URL}/subscriber/service/v1/cancel-subscription-requests`;
    return (dispatch) =>{
        return axios.post(url,payload).then((res)=>{
            return res
        },(err)=>{
            return err
        })
    }
}

export const subscriptionsCancelRequest = (payload) => {
    let url = `${API_URL}/subscriber/service/v1/cancel-subscription-requests?page=1&limit=${payload.limit}`
    return (dispatch)=>{
        return axios.get(url).then((res)=>{
            return res
        },(err)=>{
            return err
        })
    }
}