import React, {
  useEffect,
  useId,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Eye, EyeOff, Check, X } from "react-feather";
import ReactSelect, { components } from "react-select";
import AsyncSelect from "react-select/async";

import "./input.css";
import Tooltip from "./Tooltip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Input = ({
  type,
  name,
  className,
  handleChange,
  placeholder,
  label,
  error,
  errorMsg,
  value,
  disabled,
  required,
  autofocus,
  info,
  id,
  prefix,
  testid,
  slim,
  append,
  ...restProps
}) => {
  const Id = useId();
  const inputRef = useRef();

  const [selection, setSelection] = useState(null);

  const handleClick = () => {
    if (inputRef.current.type === "date") {
      inputRef.current.showPicker();
    }
  };

  useEffect(() => {
    if (inputRef?.current?.className?.includes("error")) {
      inputRef.current.focus();
    }
  }, [error]);

  document.addEventListener("wheel", function (event) {
    if (
      document.activeElement.type === "number" &&
      document.activeElement.classList.contains("noscroll")
    ) {
      document.activeElement.blur();
    }
  });

  return (
    <div
      className={`relative ${className && className !== "" ? className : ""}`}
      data-testid="wrapper-div"
    >
      {label ? (
        <label
          className="flex mb-2 text-sm text-gray-500"
          htmlFor={id ? id : Id}
        >
          {label}
          {required && <span className="text-red-500 mx-px">*</span>}
          {info && typeof info === "string" ? (
            <Tooltip direction="right" className={"ml-2"}>
              {info}
            </Tooltip>
          ) : (
            ""
          )}
        </label>
      ) : (
        ""
      )}

      <div
        className={`relative ${prefix ? "input-box" : ""}`}
        data-testid={testid || "input-div"}
      >
        {prefix && <span className="prefix">{prefix}</span>}
        <input
          name={name}
          aria-label={name}
          id={id ? id : Id}
          type={type}
          placeholder={placeholder || label}
          onChange={handleChange}
          value={value}
          disabled={disabled || false}
          autoFocus={autofocus}
          required={required !== undefined ? required : false}
          className={`... ${slim === true ? "slimbox" : "inputbox"} ${
            type === "number" ? "noscroll" : ""
          } ${
            typeof error === "string" && error.trim() !== "" ? ` error` : ""
          } `}
          ref={inputRef}
          onClick={handleClick}
          {...restProps}
        />
      </div>
      {/* {typeof error === "string" && error.trim() !== "" && (
        <div className="text-error mt-1 text-xs">{error}</div>
      )} */}
      {error && (
        <div className="text-error mt-1 text-xs">{error}</div>
      )}
      <div className="absolute inset-y-0 right-0 top-8 flex items-center pr-3">
        {append && <span className="text-xs font-medium ">{append}</span>}
      </div>
    </div>
  );
};

export { Input };

const PasswordInput = ({
  type,
  name,
  className,
  handleChange,
  placeholder,
  label,
  error,
  errorMsg,
  value,
  disabled,
  required,
  autofocus,
  info,
  passwordId,
  testid,
  slim,
  ...restProps
}) => {
  const id = useId();
  const inputRef = useRef();

  const [show, setShow] = useState(false);
  useEffect(() => {
    if (inputRef?.current?.className?.includes("error")) {
      inputRef.current.focus();
    }
  }, [error]);
  return (
    <div
      className={`relative ${className && className !== "" ? className : ""}`}
    >
      {label ? (
        <label className="flex mb-2 text-sm text-gray-500" htmlFor={id}>
          {label}
          {required && <span className="text-red-500 -mt-2 mx-px">*</span>}
          {info && typeof info === "string" ? (
            <Tooltip direction="right">{info}</Tooltip>
          ) : (
            ""
          )}
        </label>
      ) : (
        ""
      )}

      <div className="relative">
        <input
          name={name}
          autoComplete="new-password"
          id={passwordId || id}
          type={!show ? "password" : "text"}
          placeholder={placeholder || label}
          onChange={handleChange}
          value={value}
          disabled={disabled || false}
          autoFocus={autofocus}
          required={required !== undefined ? required : false}
          data-testid={testid || "password"}
          ref={inputRef}
          className={`${slim === true ? "slimbox" : "inputbox"}  ${
            typeof error === "string" && error.trim() !== "" ? `error` : ""
          } `}
          {...restProps}
        />
        <div
          className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
          onClick={() => setShow(!show)}
          data-testid="show"
        >
          {show ? <Eye size={15} /> : <EyeOff size={15} />}
        </div>
      </div>
      {typeof error === "string" && error.trim() !== "" && (
        <div className="text-error mt-1 text-xs">{error}</div>
      )}
    </div>
  );
};

export { PasswordInput };

const TextArea = ({
  type,
  name,
  className,
  handleChange,
  placeholder,
  label,
  error,
  errorMsg,
  value,
  disabled,
  required,
  autofocus,
  rows,
  info,
  slim,
  ...restProps
}) => {
  const id = useId();
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef?.current?.className?.includes("error")) {
      inputRef.current.focus();
    }
  }, [error]);
  return (
    <div
      className={`relative ${className && className !== "" ? className : ""}`}
      data-testid="wrapper-div"
    >
      {label ? (
        <label
          className="block mb-2 text-sm font-medium text-gray-600"
          htmlFor={id}
        >
          {label}
          {required && <span className="text-red-500 -mt-2 mx-px">*</span>}
          {info && typeof info === "string" ? (
            <Tooltip direction="right">{info}</Tooltip>
          ) : (
            ""
          )}
        </label>
      ) : (
        ""
      )}

      <div className="relative">
        <textarea
          name={name}
          id={id}
          type={type}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          disabled={disabled || false}
          autoFocus={autofocus}
          required={required !== undefined ? required : false}
          rows={rows || 3}
          data-testid="textarea"
          className={`${slim === true ? "slimbox" : "inputbox"}  ${
            typeof error === "string" && error.trim() !== "" ? `error` : ""
          } `}
          ref={inputRef}
          {...restProps}
        />
      </div>
      {typeof error === "string" && error.trim() !== "" && (
        <div className="text-error mt-1 text-xs">{error}</div>
      )}
    </div>
  );
};

export { TextArea };

const Select = ({
  type,
  name,
  className,
  handleChange,
  label,
  error,
  errorMsg,
  value,
  disabled,
  required,
  options,
  placeholder,
  autofocus,
  info,
  keyOptions,
  slim,
  testid,
  isClearable,
  handleClear,
  ...restProps
}) => {
  const id = useId();
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef?.current?.className?.includes("error")) {
      inputRef.current.focus();
    }
  }, [error]);
  return (
    <div
      className={`relative ${className && className !== "" ? className : ""}`}
      data-testid="wrapper-div"
    >
      {label ? (
        <label
          className="block mb-2 text-sm font-medium text-gray-900"
          htmlFor={id}
        >
          {label}
          {required && <span className="text-red-500 -mt-2 mx-px">*</span>}
          {info && typeof info === "string" ? (
            <Tooltip direction="right">{info}</Tooltip>
          ) : (
            ""
          )}
        </label>
      ) : (
        ""
      )}

      <div className="relative">
        <select
          data-testid={testid}
          name={name}
          id={id}
          type={type}
          onChange={handleChange}
          value={value}
          disabled={disabled || false}
          autoFocus={autofocus}
          required={required !== undefined ? required : false}
          className={`${slim === true ? "slimbox" : "inputbox"}  ${
            typeof error === "string" && error.trim() !== "" ? `error` : ""
          } `}
          ref={inputRef}
          {...restProps}
        >
          <option className="text-gray-500" value="">
            {placeholder ? placeholder : `-- Select ${label} --`}{" "}
          </option>
          {keyOptions &&
            keyOptions.length > 0 &&
            keyOptions?.map((each, index) => (
              <option name={each.label} value={each.value} key={index}>
                {each.label}
              </option>
            ))}
          {options &&
            options.length > 0 &&
            options?.map((each, index) => (
              <option value={each} key={index}>
                {each}
              </option>
            ))}
        </select>
        {isClearable && value && (
          <span
            className="absolute right-7 top-3 text-gray-500 hover:text-gray-800 cursor-pointer"
            onClick={
              handleClear
                ? handleClear
                : () => {
                    console.log("No Clear function provided");
                  }
            }
          >
            <X size={15} strokeWidth={3} />
          </span>
        )}
      </div>
      {typeof error === "string" && error.trim() !== "" && (
        <div className="text-error mt-1 text-xs">{error}</div>
      )}
    </div>
  );
};

export { Select };

const Checkbox = ({
  label,
  name,
  checked,
  handleChange,
  disabled,
  className,
  count,
  error,
  ...restProps
}) => {
  let uid = useId();
  return (
    <div>
      <div
        className={`checkbox ${className && className !== "" ? className : ""}`}
        data-testid="wrapper-div"
      >
        <input
          type="checkbox"
          disabled={disabled === undefined ? false : disabled}
          onClick={handleChange}
          id={uid}
          name={name}
          checked={checked}
          {...restProps}
        />
        <label className="capitalize" htmlFor={uid}>
          <span className="box">
            <Check size={15} strokeWidth={4} className="check-icon" />
          </span>
          {count !== undefined && count > 0 && (
            <span className="relative top-[-10px] left-[-12px] ">
              <span className="px-2 py-[2px] text-white rounded-full bg-sky-500">
                {count}
              </span>
            </span>
          )}
          {label || ""}
        </label>
      </div>
      {typeof error === "string" && error.trim() !== "" && (
        <div className="text-error mt-1 text-xs">{error}</div>
      )}
    </div>
  );
};

export { Checkbox };

const RadioButton = ({
  group,
  value,
  handleChange,
  checked,
  label,
  ...restProps
}) => {
  const id = useId();
  return (
    <div className="flex items-center">
      <input
        id={id}
        type="radio"
        value={value}
        name={group}
        checked={checked}
        onChange={handleChange}
        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  focus:ring-2 "
        {...restProps}
      />
      <label
        htmlFor={id}
        className="ml-2 text-sm font-medium text-gray-900 capitalize"
      >
        {label}
      </label>
    </div>
  );
};

export { RadioButton };

const ToggleButton = ({
  label,
  name,
  checked,
  handleChange,
  disabled,
  className,
  ...restProps
}) => {
  let uid = useId();
  return (
    <label className="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        value=""
        className="sr-only peer"
        checked={checked}
        onClick={handleChange}
        onChange={handleChange}
      />
      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
      <span className="ml-3 text-sm font-medium text-gray-900 ">{label}</span>
    </label>
  );
};

export { ToggleButton };

const SearchSelect = ({
  type,
  name,
  extraClasses,
  handleChange,
  label,
  error,
  errorMsg,
  value,
  disabled,
  required,
  options,
  placeholder,
  autofocus,
  info,
  testid,
  isClearable,
  loading,
  handleInputChange,
  ...restProps
}) => {
  const id = useId();

  let listOptionsNormalized = {};
  const listOptions = options?.map((each) => {
    const obj = {
      label: each.label,
      value: each.value,
    };
    listOptionsNormalized = {
      ...listOptionsNormalized,
      [each.value]: obj,
    };
    return obj;
  });

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),

    option: (provided, state) => ({
      ...provided,
      fontSize: "0.875rem",
      display: "block",
      whitespace: "pre",
      minheight: "1em",
      padding: "0px 2px 1px",
    }),

    menu: (provided) => ({
      ...provided,
      margin: 0,
      borderRadius: "0.5rem",
      zIndex: 100,
    }),

    placeholder: (provided) => ({
      ...provided,
      color: "#000000",
    }),

    control: (base, state) => ({
      display: "flex",
      justifyContent: "space-between",
      color: "#111827",
      padding: "0.2rem",
      fontSize: "0.875rem",
      width: "100%",
      borderRadius: "0.5rem",
      borderWidth: "1px",
      borderColor: "#D1D5DB",
      outline: state.isFocused && "0",
      boxShadow: state.isFocused && "0 0 0 2px #bee3f8",
      border: state.isFocused && "1px solid #63b3ed",
    }),

    indicatorSeparator: () => ({
      background: "transparent",
    }),

    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
  };

  const Input = (props) => (
    <components.Input
      {...props}
      inputClassName=" outline-none border-none shadow-none focus:ring-transparent"
    />
  );

  return (
    <div
      className={`relative ${
        extraClasses && extraClasses !== "" ? extraClasses : ""
      }`}
      data-testid="wrapper-div"
    >
      {label ? (
        <label
          className="block mb-2 text-sm font-medium text-gray-900"
          htmlFor={id}
        >
          {label}
          {required && <span className="text-red-500 -mt-2 mx-px">*</span>}
          {info && typeof info === "string" ? (
            <Tooltip direction="right">{info}</Tooltip>
          ) : (
            ""
          )}
        </label>
      ) : (
        ""
      )}

      <div className="relative" data-testid="search-select">
        <ReactSelect
          name={name}
          id={id}
          data-testid={testid}
          isLoading={loading}
          onChange={handleChange}
          menuPortalTarget={document.body}
          value={listOptionsNormalized[value] || ""}
          isDisabled={disabled || false}
          autoFocus={autofocus}
          placeholder={placeholder ? placeholder : `Select ${label}`}
          required={required !== undefined ? required : false}
          className={`bg-white  ${
            typeof error === "string" && error.trim() !== "" ? `error` : ""
          } `}
          options={options}
          components={{ Input }}
          styles={customStyles}
          isClearable={isClearable}
          onInputChange={handleInputChange}
          {...restProps}
        ></ReactSelect>
      </div>
      {typeof error === "string" && error.trim() !== "" && (
        <div className="text-error mt-1 text-xs">{error}</div>
      )}
    </div>
  );
};

export { SearchSelect };

const DateRange = ({
  type,
  name,
  className,
  handleChange,
  placeholder,
  label,
  error,
  errorMsg,
  values,
  disabled,
  required,
  autofocus,
  info,
  id,
  prefix,
  testid,
  slim,
  append,
  ...restProps
}) => {
  const Id = useId();
  const inputRef = useRef();

  const handleClick = () => {
    if (inputRef.current.type === "date") {
      inputRef.current.showPicker();
    }
  };
  return (
    <div
      className={`relative ${
        className && className !== "" ? className : ""
      } z-10`}
      data-testid="wrapper-div"
    >
      {label ? (
        <label
          className="flex mb-2 text-sm font-medium text-gray-900"
          htmlFor={id ? id : Id}
        >
          {label}
          {required && <span className="text-red-500 mx-px">*</span>}
          {info && typeof info === "string" ? (
            <Tooltip direction="right">{info}</Tooltip>
          ) : (
            ""
          )}
        </label>
      ) : (
        ""
      )}

      <div
        className={`relative ${prefix ? "input-box" : ""}`}
        data-testid={testid || "input-div"}
      >
        {prefix && <span className="prefix">{prefix}</span>}
        <DatePicker
          id={id ? id : Id}
          placeholderText={placeholder || "Select date range"}
          selectsRange={true}
          portalId="root"
          startDate={values && values[0]}
          endDate={values && values[1]}
          onChange={(update) => {
            handleChange(update);
          }}
          isClearable={true}
          className={` z-1000 ${slim === true ? "slimbox" : "inputbox"} ${
            typeof error === "string" && error.trim() !== "" ? ` error` : ""
          } `}
          autoComplete="off"
          {...restProps}
        />
      </div>
      {typeof error === "string" && error.trim() !== "" && (
        <div className="text-error mt-1 text-xs">{error}</div>
      )}
      <div className="absolute inset-y-0 right-0 top-8 flex items-center pr-3">
        {append && <span className="text-xs font-medium ">{append}</span>}
      </div>
    </div>
  );
};

export { DateRange };

const AsyncSearchSelect = ({
  handleBlur,
  type,
  name,
  extraClasses,
  handleChange,
  label,
  error,
  errorMsg,
  value,
  disabled,
  required,
  options,
  placeholder,
  autofocus,
  info,
  testid,
  isClearable,
  loading,
  loadOptions,
  handleInputChange,
  slim,
  ...restProps
}) => {
  const id = useId();

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "0.875rem",
      display: "block",
      whitespace: "pre",
      minheight: "1em",
      padding: "5px 10px",
    }),

    menu: (provided) => ({
      ...provided,
      margin: 0,
      // paddingLeft: "3px",
      borderRadius: "0.5rem",
    }),

    placeholder: (provided) => ({
      ...provided,
      color: "#000000",
    }),

    control: (base, state) => ({
      display: "flex",
      justifyContent: "space-between",
      color: "#111827",
      padding: slim ? "0" : "0.2rem",
      fontSize: "0.875rem",
      width: "100%",
      borderRadius: "0.5rem",
      borderWidth: "1px",
      borderColor: "#D1D5DB",
      outline: state.isFocused && "0",
      boxShadow: state.isFocused && "0 0 0 2px #bee3f8",
      border: state.isFocused && "1px solid #63b3ed",
    }),

    indicatorSeparator: () => ({
      background: "transparent",
      display: "none",
      border: "none",
    }),

    container: (provided) => ({
      ...provided,
      width: "100%",
    }),

    input: (base) => ({
      ...base,
      padding: "0 0 0 0",
      "input:focus": {
        boxShadow: "none",
      },
    }),

    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  return (
    <div
      className={`relative ${
        extraClasses && extraClasses !== "" ? extraClasses : ""
      }`}
      data-testid="wrapper-div"
    >
      {label ? (
        <label className="block mb-2 text-sm text-gray-900" htmlFor={id}>
          {label}
          {required && <span className="text-red-500 -mt-2 mx-px">*</span>}
          {info && typeof info === "string" ? (
            <Tooltip direction="right">{info}</Tooltip>
          ) : (
            ""
          )}
        </label>
      ) : (
        ""
      )}

      <div className="relative" data-testid="search-select">
        <AsyncSelect
          name={name}
          onBlur={handleBlur ? handleBlur : void undefined}
          id={id}
          data-testid={testid}
          isLoading={loading}
          loadOptions={loadOptions}
          onChange={handleChange}
          isDisabled={disabled || false}
          value={value}
          autoFocus={autofocus}
          menuPortalTarget={document.body}
          placeholder={placeholder ? placeholder : `Select ${label}`}
          required={required !== undefined ? required : false}
          className={`  ${
            typeof error === "string" && error.trim() !== "" ? `error` : ""
          } `}
          styles={customStyles}
          isClearable={isClearable}
          {...restProps}
        ></AsyncSelect>
      </div>
      {typeof error === "string" && error.trim() !== "" && (
        <div className="text-error mt-1 text-xs">{error}</div>
      )}
    </div>
  );
};

export { AsyncSearchSelect };
