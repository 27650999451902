import { useDispatch, useSelector } from "react-redux";
import LoadingDiv from "../../components/LoadingDiv";
import { getCameras } from "../../state/actions/cameras-actions";
import { useEffect } from "react";
import moment from "moment";
import { useState } from "react";
import Drawer from "../../components/Drawer";
import CameraDetails from "./CameraDetails";
import ContentWrapper from "../../components/ContentWrapper";
import { Link } from "react-router-dom";
const MyCameras = () => {
  const { profileData, loading, cameraData } = useSelector((state) => ({
    profileData: state?.profile?.profileData,
    loading: state?.cameras?.loaders?.cameras,
    cameraData: state?.cameras?.cameraData,
  }));

  const dispatch = useDispatch();

  const [selectedCamera, setSelectedCamera] = useState("");

  useEffect(() => {
    if(profileData?.id){
      dispatch(getCameras({id:profileData?.id,limit:200}));
    }
  }, [profileData]);

  const [query,setQuery] = useState('')
  const changeHandler = (e) =>{
    setQuery(e.target.value)
  }
  const filteredCamera = cameraData?.data?.filter((item)=>item?.inventory_serial?.toLowerCase()?.includes(query?.toLocaleLowerCase()))

  return (
    <ContentWrapper title={"CAMERA LIST "} placeholder={'Search Camera'} searchInput={true} changeHandler={changeHandler} >
      {loading ? (
        <LoadingDiv />
      ) : filteredCamera?.length > 0 ? (
        <div className="grid lg:grid-cols-4 md:grid-cols-3 xs:grid-cols-1 gap-6">
          {filteredCamera?.map((each,idx) => (
            <Link key={idx}
              className=" rounded-xl shadow px-4 py-4 bg-white grid gap-y-2 border-white cursor-pointer hover:bg-slate-100"
              to={{
                pathname: `/cameras/subscription/${each?.id}`,
                search: `inventory-serial=${encodeURIComponent(each?.inventory_serial)}&custom-label=${each?.custom_label ? each.custom_label : each?.ipcam_label}`
              }}
            >
              
              <p className="font-bold text-lg">{each?.inventory_serial}</p>

              <p className="text-gray-900 text-sm font-medium">
                {moment(each?.created_at).format("lll")}
              </p>
            </Link>
          ))}
        </div>
      ) : (
        <div>No Cameras Found</div>
      )}

      <Drawer
        open={selectedCamera !== ""}
        onClose={() => {
          setSelectedCamera("");
        }}
        size={"large"}
        title={"Camera details"}
        body={<CameraDetails camera_id={selectedCamera} />}
      />
    </ContentWrapper>
  );
};

export default MyCameras;
