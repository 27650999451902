import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPackages, getSubscriptions, } from "../../state/actions/cameras-actions";
import moment from "moment/moment";
import Button from "../../components/Button";
import ContentWrapper from "../../components/ContentWrapper";
import { useState } from "react";
import Modal from "../../components/Modal";
import NewPackage from "./NewPackage";
import { useLocation, useParams } from "react-router-dom";
import { calculateRemainingDays } from "../../utils/SubscriptionDateHelpers";
import LoadingDiv from "../../components/LoadingDiv";
import { activeTrailPackage, claimRefund, getTrialPackage, subscriptionsCancelRequest } from "../../state/actions/trial-package-actions";
import { errorAlert, successAlert } from "../../state/actions/alert-actions";
import { Input, TextArea } from "../../components/Inputs";
import { BsThreeDotsVertical } from "react-icons/bs";

const CameraDetails = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let inventorySerial = queryParams.get('inventory-serial')
  const tabs = [
    {
      id: 1,
      name: 'Active',
      label: 'active'
    },
    {
      id: 2,
      name: "Pending",
      label: 'pending'
    },
    {
      id: 3,
      name: "History",
      label: 'history'
    }
  ]
  const [activeTab, setActiveTab] = useState('Active')
  const handleTab = (tabName) => {
    setActiveTab(tabName)
  }


  const dispatch = useDispatch();
  const params = useParams();

  const { id: camera_id } = params;

  const { profileData, subscriptionData, loginUser } = useSelector(
    (state) => ({
      profileData: state?.profile?.profileData,
      loading: state?.profile?.loaders?.profile,
      cameraData: state?.cameras?.cameraData,
      subscriptionData: state?.cameras?.subscriptionData,
      subscriptionDataLoader: state?.cameras?.loaders?.subscription,
      loginUser: state?.profile?.profileData?.subscriber_details

    })
  );
  const [trailPackage, setTrailPackage] = useState();
  const [newOpen, setNewOpen] = useState(false);
  const [refundModal, setRefundModal] = useState(false);
  const [checkStatusModal, setCheckStatusModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState()
  const [statusData, setStatusData] = useState()
  const [loading, setLoading] = useState(true);
  const [renderPage, setRenderPage] = useState(false);

  const [cancelRequestData, setCancelRequestData] = useState();
  console.log('cancelRequestData', cancelRequestData)

  const fetchsubscriptionsCancelRequest = () => {
    dispatch(subscriptionsCancelRequest({ limit: 1000 })).then((res) => {
      if (res.status === 200) {
        setCancelRequestData(res?.data?.data)
      }
    })
  }
  useEffect(() => {
    fetchsubscriptionsCancelRequest()
  }, [camera_id])

  useEffect(() => {
    if (loginUser?.subscriber_id && inventorySerial) {
      dispatch(getTrialPackage({ subscriberId: loginUser?.subscriber_id, inventorySerial: inventorySerial })).then((res) => {

        setLoading(false)
        if (res === undefined || res?.status === 400 || res?.status === 204) {
          setLoading(true)
          if (camera_id && newOpen) {
            dispatch(getPackages(camera_id)).then((res) => {
              if (res?.status === 200) {
                setLoading(false)
              }
            })
          }
          if (profileData?.id) {
            dispatch(getSubscriptions(profileData?.id, camera_id)).then((res) => {
              setLoading(false)
              if (res?.status === 200) {
                setLoading(false)
              }
            })
          }
        } else if (res.status === 200) {
          setTrailPackage(res.data)
          setLoading(false)
        } else if (res.status === 422) {
          setLoading(false)
          console.log("@error")
        }
      }, (err) => {
        setLoading(false)
        console.log("@errorTrailPackage", err)
      })
    }
  }, [profileData, camera_id, renderPage]);

  function expireCheckDate(dateStr) {
    const targetDate = new Date(dateStr);
    const currentDate = new Date();
    if (targetDate > currentDate) {
      return false;
    } else if (targetDate.toDateString() === currentDate.toDateString()) {
      return false;
    } else {
      return true;
    }
  }
  function activeCheckDate(dateStr) {
    const targetDate = new Date(dateStr);
    const currentDate = new Date();
    if (targetDate > currentDate) {
      return true;
    } else if (targetDate.toDateString() === currentDate.toDateString()) {
      return true;
    } else {
      return false;
    }
  }

  const [activateLoader, setActivateLoader] = useState(false)
  function activatePackage(packageId, planId) {
    setActivateLoader(true)
    let data = {
      "package_id": packageId,
      "package_plan_id": planId,
      "payment_gateway": "cash",
      // "payment_method": "cash",
      "qty": 1,
      "subscriber_id": 15710,
      "created_by": `${profileData?.username}`,
      "subscriber_ipcam_id": 1 * camera_id
    }
    dispatch(activeTrailPackage(data)).then((res) => {
      if (res.status === 200) {
        dispatch(successAlert("Your trail package has been activated Successfully ."))
        setLoading(false)
        window.location.href = `/cameras/subscription/${camera_id}?inventory-serial=${inventorySerial}`
        setRenderPage(true)
      }
    })
  }
  const [threeDotstate, setThreeDotState] = useState(false);

  const activeTabCss = `text-[#1A374D] border-b-2 border-[#1A374D] rounded-t-lg active dark:text-[#1A374D] dark:border-[#1A374D]`;
  const inActiveTabCss = `border-b-2 border-transparent rounded-t-lg hover:text-blue-600 hover:border-blue-500 dark:hover:text-blue-500`;

  return (
    <>{
      trailPackage?.plans?.length > 0 ?
        loading ? <LoadingDiv /> : <ContentWrapper title="TRIAL PACKAGE">
          <div>
            <div className="grid gap-4 border-white rounded-xl shadow px-4 py-6 bg-white">
              <div className="flex justify-between">
                <p className="font-medium flex gap-x-2 items-start">
                  {trailPackage?.name}
                </p>
                <div>
                  {activateLoader ? <LoadingDiv /> : <Button handleClick={() => activatePackage(trailPackage?.id, trailPackage?.plans[0]?.id)}>
                    Activate Package
                  </Button>}
                </div>
              </div>
            </div>
          </div>
        </ContentWrapper>
        :
        (loading ? <LoadingDiv /> : <ContentWrapper title={"SUBSCRIPTIONS"}>
          <div>
            <div className='flex justify-between items-center '>
              <div>
                <ul className="flex flex-wrap -mb-px">
                  {tabs?.map((tab, idx) => {
                    return (
                      <li className="me-2" key={idx}>
                        <a href={`#${tab.label}`} className={`inline-block text-base font-medium mr-4 pr-2 pb-3 ${activeTab === tab.name ? activeTabCss : inActiveTabCss}`} onClick={() => handleTab(tab.name)}>{tab.name}</a>
                      </li>)
                  })}
                </ul>
              </div>
              <div className="flex items-center justify-center gap-3">
                <div>
                  <Button handleClick={() => {
                    setNewOpen(true);
                  }}>
                    Buy Package
                  </Button>
                </div>

              </div>
            </div>
            {
              activeTab === 'Active' &&
              <div className="mt-4">
                {subscriptionData?.packages?.length > 0 && subscriptionData?.packages?.filter((each) => ((each?.subscription_status === 1 && activeCheckDate(each.subscription_expiry_date))))[0] !== undefined ?
                  ([subscriptionData?.packages?.filter((each) => ((each?.subscription_status === 1 && activeCheckDate(each.subscription_expiry_date))))[0]].map((each) => {
                    console.log('active Each', each)
                    let findRefundRequestStatus = cancelRequestData?.length > 0 && cancelRequestData?.find((item) => item?.order_id === each?.order_id);
                    // console.log('findRefundRequestStatus',findRefundRequestStatus)
                    return (
                      <div className="grid gap-4 border-white rounded-xl shadow px-4 py-6 bg-white">
                        <div className="flex justify-between">
                          <div className="flex gap-2 items-center">
                            <p className="font-medium flex gap-x-2 items-start">
                              {each?.name}
                            </p>
                            {findRefundRequestStatus && Object.keys(findRefundRequestStatus)?.length > 0 ?
                              (findRefundRequestStatus?.subscription_cancel_detail?.status === 'pending' ? <span className="px-2 rounded-md bg-red-400 text-white capitalize">Refund Request {findRefundRequestStatus?.subscription_cancel_detail?.status}</span> :
                                <>

                                  <span className={`${findRefundRequestStatus?.subscription_cancel_detail?.status === 'accepted' ? 'bg-green-400 hover:bg-green-500' : 'bg-red-400 hover:bg-red-500'}  px-1.5 py-1 text-white rounded-md text-sm capitalize cursor-pointer`} onClick={() => {
                                    setCheckStatusModal(true); setStatusData(findRefundRequestStatus)
                                  }}>
                                    Refund Request {findRefundRequestStatus?.subscription_cancel_detail?.status === 'accepted' ? "approved" : 'rejected'}
                                  </span>

                                </>) : null
                            }
                          </div>
                          <p>
                            <span className="text-gray-400 text-sm font-medium">
                              Remaining:  
                            </span>
                            <span className="font-semibold">
                              {calculateRemainingDays(
                                each?.subscription_expiry_date
                              )}{" "}
                              Days{" "}
                            </span>
                          </p>
                        </div>
                        <div className=" flex justify-between ">
                          <p
                            title={`Start Date: ${moment(each?.start_date).format(
                              "LL"
                            )}`}
                            className=""
                          >
                            <span className="text-gray-400 text-sm font-medium">
                              Expires on:{" "}
                            </span>
                            <span className="font-semibold">
                              {moment(each?.subscription_expiry_date).format("LL")}
                            </span>
                          </p>
                          {findRefundRequestStatus && Object.keys(findRefundRequestStatus)?.length > 0 ? null
                            : (
                              <div className="relative">
                                <span onClick={() => setThreeDotState(!threeDotstate)} className="cursor-pointer active:text-gray-500">
                                  <BsThreeDotsVertical size={18} />
                                </span>
                                {threeDotstate && (
                                  <div className="absolute right-8 -top-1.5 w-[150px]">
                                    <Button extraClasses={'w-[150px]'} variant={'error'} handleClick={() => {
                                      setRefundModal(true); setSelectedPackage(each)
                                    }}>
                                      Cancel Package
                                    </Button>
                                  </div>
                                )}
                              </div>
                            )
                          }
                        </div>
                      </div>
                    )
                  })) : (
                    <div className="my-8">
                      <p className="text-center font-medium">No Records Founds</p>
                    </div>
                  )

                }
              </div>}
            {activeTab === 'Pending' &&
              <div>
                {subscriptionData?.packages?.length > 0 && (subscriptionData?.packages
                  ?.filter((each) => ((each?.subscription_status === 1 && activeCheckDate(each.subscription_expiry_date)))))?.length > 1 ? (
                  <div className="grid gap-y-4 mt-4">
                    {subscriptionData?.packages
                      ?.filter((each) => ((each?.subscription_status === 1 && activeCheckDate(each.subscription_expiry_date))))?.slice(1)
                      ?.map((each, idx, arr) => {
                        let findRefundRequestStatus = cancelRequestData?.length > 0 && cancelRequestData?.find((item) => item?.order_id === each?.order_id);
                        console.log('findRefundRequestStatus', findRefundRequestStatus)
                        return (
                          <div className="grid gap-4 border-white rounded-xl shadow px-4 py-6 bg-white">
                            <div className="flex justify-between">
                              <div className="flex gap-2 items-center">
                                <p className="font-medium flex gap-x-2 items-start">
                                  {each?.name}
                                </p>
                                {(arr?.length - 1 === idx) && findRefundRequestStatus && Object.keys(findRefundRequestStatus)?.length > 0 ?
                                  (findRefundRequestStatus?.subscription_cancel_detail?.status === 'pending' ? <span className="px-2 rounded-md bg-red-400 text-white capitalize">{findRefundRequestStatus?.subscription_cancel_detail?.status}</span> :
                                    <div>
                                      <span className={`${findRefundRequestStatus?.subscription_cancel_detail?.status === 'accepted' ? 'bg-green-400 hover:bg-green-500' : 'bg-red-400 hover:bg-red-500'}  px-1.5 py-1 text-white rounded-md text-sm capitalize cursor-pointer `} onClick={() => {
                                        setCheckStatusModal(true); setStatusData(findRefundRequestStatus)
                                      }}>
                                        {findRefundRequestStatus?.subscription_cancel_detail?.status === 'accepted' ? "approved" : 'rejected'}
                                      </span>
                                    </div>) : null
                                }
                              </div>
                            </div>
                            <div className=" flex justify-between ">
                              <p
                                title={`Start Date: ${moment(each?.start_date).format(
                                  "LL"
                                )}`}
                                className=""
                              >
                                <span className="text-gray-400 text-sm font-medium">
                                  Expires on:
                                </span>
                                <span className="font-semibold">
                                  {moment(each?.subscription_expiry_date).format("LL")}
                                </span>
                              </p>
                              {(arr?.length - 1 === idx) && findRefundRequestStatus && Object.keys(findRefundRequestStatus)?.length > 0 ?
                                null : (arr?.length - 1 === idx) && (<div className="relative">
                                  <span onClick={() => setThreeDotState(!threeDotstate)} className="cursor-pointer active:text-gray-500">
                                    <BsThreeDotsVertical size={18} />
                                  </span>
                                  {threeDotstate && (
                                    <div className="absolute right-8 -top-1.5 w-[150px]">
                                      <Button extraClasses={'w-[150px]'} variant={'error'} handleClick={() => {
                                        setRefundModal(true); setSelectedPackage(each)
                                      }}>
                                        Cancel Package
                                      </Button>
                                    </div>
                                  )}
                                </div>)
                              }
                            </div>
                          </div>
                        )
                      })}
                  </div>
                ) : (
                  <div className="my-8">
                    <p className="text-center font-medium">No Records Founds</p>
                  </div>
                )}
              </div>}
            {activeTab === 'History' &&
              <div>
                {subscriptionData?.packages?.length > 0 &&
                  subscriptionData?.packages?.filter(
                    (each) => each?.subscription_status === 0
                  ).length > 0 ? (
                  <div className="my-4">
                    <div className="grid gap-y-4 mt-4  opacity-75">
                      {subscriptionData?.packages
                        ?.filter((each) => each?.subscription_status === 0 || expireCheckDate(each.subscription_expiry_date))
                        ?.map((each) => (
                          <div className="grid gap-4 border-white rounded-xl shadow px-4 py-6 bg-white">
                            <div className="flex justify-between">
                              <p className="font-medium flex gap-x-2 items-start">
                                {each?.name}
                              </p>
                              <p></p>
                            </div>
                            <div className=" flex justify-between ">
                              <p>
                                <span className="text-red-400 text-base font-semibold uppercase">
                                  Expired
                                </span>
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                ) : (
                  <div className="my-8">
                    <p className="text-center font-medium">No Records Founds</p>
                  </div>
                )}
              </div>}
          </div>
          <Modal
            open={newOpen}
            onClose={() => {
              setNewOpen(false);
            }}
            body={
              <NewPackage
                camera_id={camera_id}
                handleClose={() => {
                  setNewOpen(false);
                }}
              />
            }
            className={"xs:w-full md:w-3/5"}
          />
          <Modal
            open={refundModal}
            onClose={() => {
              setRefundModal(false);
            }}
            body={
              <Refund
                selectedPackage={selectedPackage}
                camera_id={camera_id}
                profileData={profileData}
                fetchsubscriptionsCancelRequest={fetchsubscriptionsCancelRequest}
                setThreeDotState={setThreeDotState}
                handleClose={() => {
                  setRefundModal(false); setThreeDotState(false)
                }}
              />
            }
            className={"xs:w-full md:w-3/5"}
          />
          <Modal
            open={checkStatusModal}
            onClose={() => {
              setCheckStatusModal(false);
            }}
            body={
              <StatusUI
                statusData={statusData}
                camera_id={camera_id}
                profileData={profileData}
                handleClose={() => {
                  setCheckStatusModal(false); setThreeDotState(false)
                }}
              />
            }
            className={"xs:w-full md:w-1/3"}
          />
        </ContentWrapper>)
    }</>
  )
}

export default CameraDetails

const StatusUI = ({ statusData, handleClose }) => {
  console.log('statusData', statusData)
  const UI = (key, value) => {
    return (
      <div className="py-1">
        <p className="d-flex justify-start items-end">
          <span className="text-base text-gray-800 font-semibold">{key}</span>
          {key === 'Status :' ? <span className={`text-sm px-2.5 py-1.5 ml-2 capitalize rounded-full text-white ${value === 'accepted' ? 'bg-green-700' : 'bg-red-500'}`}>{value}</span> : <span className="text-sm font-medium text-gray-800">{value}</span>}
        </p>
      </div>
    )
  }
  return (
    <div className="p-4">
      {/* <p className="text-left">{UI('Status :',statusData?.subscription_cancel_detail?.status)}</p> */}
      {/* {UI('Full Name : ',statusData?.full_name)}
      {UI('Email : ',statusData?.email)} */}
      {statusData?.subscription_cancel_detail?.status === 'accepted' ? UI('Approved Remarks : ', statusData?.subscription_cancel_detail?.acceptance_reason) : UI('Rejection Remarks : ', statusData?.subscription_cancel_detail?.rejection_reason)}
      <div className="mt-2 flex justify-end items-end">
        <Button variant={'error'} handleClick={() => {
          handleClose()
        }}>
          Close
        </Button>
      </div>
    </div>
  )
}

const Refund = ({ handleClose, selectedPackage, profileData, fetchsubscriptionsCancelRequest, setThreeDotState }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let inventorySerial = queryParams.get('inventory-serial')
  let custom_label = queryParams.get('custom-label')
  const { id } = useParams();

  const dispatch = useDispatch()
  const initialState = {
    selectedPlan: {},
    showModal: false,
    formData: {
      data: {
        reason: ''
      },
      error: {}
    }
  }

  const [stateData, setStateData] = useState({ ...initialState });
  const { showModal, formData, selectedPlan } = stateData

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStateData((state) => ({
      ...state,
      formData: { ...state.formData, data: { ...state.formData.data, [name]: value } }
    }))
  }


  useEffect(() => {
    const plan = selectedPackage?.plans?.find((plan) => selectedPackage?.id === plan?.package_id);
    setStateData((state) => ({
      ...state,
      selectedPlan: { ...plan }
    }))
  }, [selectedPackage])

  const handleToggle = () => {
    if (stateData?.formData?.data?.reason?.length >= 10) {
      setStateData((state) => ({
        ...state,
        showModal: true,
        formData: { ...state.formData, error: { ...state.formData.error, reason: '' } }
      }))
    } else {
      setStateData((state) => ({
        ...state,
        formData: { ...state.formData, error: { ...state.formData.error, reason: 'The field "Reason" is required and must be at least 10 characters long' } }
      }))
    }
  }
  const handleSubmit = () => {
    const payload = {
      "full_name": profileData?.subscriber_details?.full_name,
      "mobile": profileData?.subscriber_details?.mobile_no,
      "email": profileData?.email,
      "camera_name": inventorySerial,
      "device_id": custom_label,
      // "purchase_date": "2081-09-23",
      "request_amount": selectedPlan?.package_price * 1,
      "reason": formData?.data?.reason,
      "order_id": selectedPackage?.order_id,
      "subscriber_id": profileData?.id
    }
    dispatch(claimRefund(payload)).then((res) => {
      if (res.status === 200) {
        dispatch(successAlert(res?.data?.message))
        setStateData({ ...initialState })
        handleClose()
        dispatch(getSubscriptions(profileData?.id, id))
        fetchsubscriptionsCancelRequest()
      }
      if (res.status === 422) {
        console.log('res', res)
        // dispatch(errorAlert(res.data.message))
      }
    })
  }
  return (
    <>
      <div className="grid gap-4 p-4">
        <p className="font-semibold text-lg">Request for Cancellation </p>
        <div>
          {/* <Input disabled label={'Full Name'} value={profileData?.subscriber_details?.full_name}/>
          <Input disabled label={'Mobile No.'} value={profileData?.subscriber_details?.mobile_no}/>
          <Input disabled label={'Email'} value={profileData?.email}/> */}
          {/* <Input label={'Request Amount'} value={selectedPlan?.package_price*1}/> */}
          <div className="mt-2">
            <TextArea
              label={'Reason for Cancellation'}
              name={'reason'}
              value={stateData?.formData?.data?.reason}
              error={formData?.error?.reason}
              handleChange={handleChange}
              required
              placeholder={'Write a reason for Cacellation at least 20 character...'}
              rows={5}
            />
          </div>
        </div>
      </div>
      <div className=" flex gap-x-4 justify-end p-6">
        <div>
          <Button handleClick={handleClose} variant={"error"}>
            Cancel
          </Button>
        </div>
        <div className="">
          <Button
            handleClick={handleToggle}
          >
            Submit
          </Button>
        </div>
      </div>
      <Modal
        open={showModal}
        titleFields={''}
        onClose={() => {
          setStateData((state) => ({ ...state, showModal: false }));
        }}
        body={
          <div className="p-4">
            <p>Are You Sure Want to Cancle ?</p>
            <div className=" flex gap-x-4 justify-end p-6">
              <div>
                <Button handleClick={handleClose} variant={"error"}>
                  Cancel
                </Button>
              </div>
              <div className="">
                <Button
                  handleClick={handleSubmit}
                >
                  Yes
                </Button>
              </div>
            </div>
          </div>
        }
        className={"xs:w-full md:w-3/5"}
      />
    </>
  )
}